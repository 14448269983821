﻿/**
 *  @ngdoc controller
 *  @name PracticeMembership Directive
 *  @description PracticeMembership Directive
 */
(function () {
    "use:strict";

    angular.module("myApp").directive('practiceMembershipModel', function () {
        return {
            controller: 'practiceMembershipController',
            restrict: 'E',
            scope: {
                modelId: "@",
                stateName : "@",
                data: '='
            },
            templateUrl: 'app/directive/practiceMembership/practiceMembershipdir.html'
        }
    }).controller('practiceMembershipController', function ($scope, practiceMemberShipResource) {
        $scope.formDisabled = false;
        if ($scope.stateName == "customerInfo") {
            $scope.formDisabled = true;
        }

        $scope.data.saveMembership = () => {
            $scope.data.customer.memberShipTracking = [];
            for (var i = 0; i < $scope.data.customer.practiceMembership.length; i++) {
                if ($scope.data.customer.practiceMembership[i].selected) {
                    var member = $scope.data.customer.practiceMembership[i];
                    $scope.data.customer.practiceMembership[i].memberShipId = member.id;
                    if (member.name == "Other") {
                        $scope.data.customer.practiceMembership[i].other = $scope.data.customer.membershipOther;
                    }
                    $scope.data.customer.memberShipTracking.push($scope.data.customer.practiceMembership[i]);
                }
            }
            $scope.data.checkMemberShipDataExists();
        }

        $scope.data.loadMembership = function () {
            if ($scope.data.customer.memberShipTracking.length > 0) {
                angular.forEach($scope.data.customer.memberShipTracking, function (item) {
                    for (var i = 0; i < $scope.data.customer.practiceMembership.length; i++) {//angular.forEach(cr.customer.practiceMembership, function (master) {
                        if (item.memberShipId === $scope.data.customer.practiceMembership[i].id) {
                            $scope.data.customer.practiceMembership[i].selected = item.selected;
                            break;
                        }
                    }
                });
            } else {
                angular.forEach($scope.data.customer.practiceMembership, function (master) {
                    master.selected = false;
                });
            }
            $scope.data.getSavedDataForMemberShip();
            $scope.data.checkMemberShipDataExists();
        }

        $scope.data.isOtherMemberShip = () => {
            return $scope.data.customer.practiceMembership.some(x => x.name == 'Other' && x.selected === true);
        }

        $scope.data.getPracticeMemberShip = () => {
            practiceMemberShipResource.getPracticeMemberShip(res => {
                $scope.data.membershipInfoList = res.result;
            });
        }
    });

}(window.angular));